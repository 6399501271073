<template>
  <div>
    <el-menu mode="horizontal" :ellipsis="false"
             v-if="user.id" class="n-menu">
      <el-menu-item index="1">
        <img style="height: 50px;" src="../assets/logo.png">
      </el-menu-item>
      <el-sub-menu index="2">
        <template #title>{{ currentProjectName }}</template>
        <el-menu-item v-for="(project, index) in projects" :index="`2-${index}`" :key="index"
                      @click="changeProject(project.id)">
          {{ project.name }}
        </el-menu-item>
        <el-divider style="margin: 0"/>
        <el-menu-item index="1-0" @click="openCreateProjectForm()">Создать проект</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="3" style="color: white" @click="openLink">
        Расширение Google Chrome
      </el-menu-item>
      <el-divider style="border: none"/>
      <el-sub-menu index="4">
        <template #title>{{ user.name || 'Пользователь' }}</template>
        <el-menu-item index="4-1" @click="$router.push('/profile')">Профиль</el-menu-item>
        <el-menu-item index="4-2" @click="logout">Выйти</el-menu-item>
      </el-sub-menu>
    </el-menu>

    <el-dialog v-model="visibleCreateProjectForm"
               title="Создание проекта"
               width="30%">
      <el-form label-position="top"
               ref="createProjectFormRef"
               :model="createProjectForm"
               :rules="createProjectFormRules">
        <el-form-item label="Название проекта" prop="name"
                      :error="errors.get('name')">
          <el-input v-model="createProjectForm.name" placeholder="Введите название проекта"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="visibleCreateProjectForm = false">Отмена</el-button>
        <el-button type="primary" @click="createProject()">Создать проект</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {CREATE_PROJECT, GET_PROJECT, LOGOUT} from "@/store/actions";
import {SET_USER} from "@/store/mutations";
import {Errors} from "@/utils/errors";

export default {
  name: 'nexus-header',
  data() {
    return {
      errors: new Errors(),
      visibleCreateProjectForm: false,
      createProjectForm: {
        name: "",
      },
      createProjectFormRules: {
        name: [{required: true, message: 'Введите название проекта', trigger: 'blur'}],
      },
    }
  },
  methods: {
    openLink() {
      window.open("https://chromewebstore.google.com/detail/nexus-%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D1%8F-amocrm-%D0%B2/jfdjneecbcbjanijhgndpemdolbdpoof",'_blank');
    },
    changeProject(id) {
      this.getProject(id);
    },
    getProject(id) {
      this.$store.dispatch(GET_PROJECT, id).then(() => {
        this.$router.push('/project/' + id);
      }).catch((err) => {
        console.log(err)
      });
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$store.commit(SET_USER, {})
        this.$router.push('/login');
      }).catch((err) => {
        console.log(err)
      });
    },
    openCreateProjectForm() {
      this.createProjectForm.name = "";
      this.visibleCreateProjectForm = true;
    },
    createProject() {
      this.errors.clear();
      this.$refs.createProjectFormRef.validate((valid) => {
        if (valid) {
          this.$store.dispatch(CREATE_PROJECT, this.createProjectForm).then((resp) => {
            this.getProject(resp.data.data.id);
            this.visibleCreateProjectForm = false;
          }).catch((err) => {
            this.errors.record(err.response.data.errors)
          });
        }
      });
    }
  },
  computed: {
    user() {
      return this.$store.getters.user.origin
    },
    projects() {
      return this.$store.getters.projects
    },
    currentProjectName() {
      return (this.projects.find(project => project.id === parseInt(this.$route.params.project_id)) || {}).name || 'Проекты'
    }
  }
}
</script>

<style>
.n-menu {
  background: black;
  padding: 0 180px;
}

.n-menu .el-sub-menu__title {
  color: white !important;
}

.n-menu .el-sub-menu__title:hover {
  background: black !important;
}

.n-menu .el-menu-item:hover {
  background: black !important;
}
</style>
