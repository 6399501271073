import axios from 'axios';
import router from "@/router"
import store from "@/store"
import {SET_USER} from "@/store/mutations";

export default function setup() {
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (err) {
        switch (err.response.status) {
            case 401:
                store.commit(SET_USER, {})
                router.push("/login");
                break;
        }
        return Promise.reject(err);
    });
}