<template>
  <el-container>
    <el-header class="header">
      <nexus-header/>
    </el-header>
    <el-main>
      <router-view/>
    </el-main>
    <el-footer class="footer">
      <nexus-footer/>
    </el-footer>
  </el-container>
</template>

<script>
import NexusHeader from "@/components/NexusHeader";
import NexusFooter from "@/components/NexusFooter";

export default {
  name: 'App',
  components: {
    NexusFooter,
    NexusHeader,
  }
}
</script>

<style>
body {
  margin: 0;
}

.header {
  padding: 0 !important;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0;
}

.box-card .el-card__header {
  background: black;
  color: white;
}
</style>
