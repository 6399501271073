<template>
  <div v-if="user.id" style="padding:5px; background: black; color: white; height: 100%">
    <el-row>
      <el-col :offset="4">
        <div>
          <el-link href="https://nexus.macrm.ru/agreement/pdf" target="_blank" style="color: white">
            Политика конфиденциальности
          </el-link>
        </div>
        <div>
          <el-link @click="$router.push('/contact')" style="color: white">Контакты и реквизиты</el-link>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'nexus-footer',
  computed: {
    user() {
      return this.$store.getters.user.origin
    },
  }
}
</script>

<style>
</style>
