<template>
  <div>
    <h4>Подключение AMOCRM</h4>
    <el-row v-if="!integration.origin.amocrm_account_id">
      <el-col>
        <el-form-item>
          <el-button type="primary" :loading="loadConnectAmocrm" @click="connectAmocrm">Подключить</el-button>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-form label-position="top" v-model="amocrm_account" style="width: 100%">
        <el-row>
          <el-col :span="6">
            <el-form-item label="Id аккаунта">
              <el-input v-model="amocrm_account.id" readonly/>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="6">
            <el-form-item label="Домен аккаунта">
              <el-input v-model="amocrm_account.domain" readonly/>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="6">
            <el-form-item label="Название">
              <el-input v-model="amocrm_account.name" readonly/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-popconfirm
                  confirm-button-text="Да"
                  cancel-button-text="Нет"
                  confirm-button-type="danger"
                  title="Вы уверены?"
                  @confirm="disconnectAmocrm">
                <template #reference>
                  <el-button :loading="loadDisconnectAmocrm" type="danger">
                    Отключить
                  </el-button>
                </template>
              </el-popconfirm>
              <el-button type="primary" :loading="loadCheckAmocrm" @click="checkAmocrm">Проверить
                подключение
              </el-button>
              <el-button v-if="integration.object.sync_status === 'new'" type="success" :loading="loadCheckAmocrm"
                         @click="openSyncForm">
                Выполнить синхронизацию
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-form v-if="integration.object.sync_status !== 'new'" label-position="top" style="width: 100%"
               ref="integrationFormRef"
               :model="integration.object"
               :rules="integrationFormRules">
        <el-row>
          <el-col :span="6">
            <el-form-item label="Дополнительное поле VK">
              <el-select v-model="integration.object.amocrm_custom_field_id" placeholder="Выберете поле"
                         style="width: 100%" disabled>
                <el-option
                    v-for="custom_field in amocrm_custom_fields"
                    :key="custom_field.id"
                    :label="custom_field.name"
                    :value="custom_field.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="6">
            <el-form-item label="Способ выбора сделки" prop="selection_method"
                          :error="errors.get('selection_method')">
              <el-select v-model="integration.object.selection_method"
                         placeholder="Выберете правило" style="width: 100%" clearable>
                <el-option
                    v-for="(name, key) in selectionMethods"
                    :key="key"
                    :label="name"
                    :value="key"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="6">
            <el-form-item label="Статус синхронизации">
              <el-input class="input-refresh"
                        :value="statuses[integration.object.sync_status] || integration.object.sync_status" readonly>
                <template #prefix>
                  <el-icon v-if="integration.object.sync_status === 'process'"
                           style="animation: 2s linear infinite rotate" color="blue">
                    <Loading/>
                  </el-icon>
                  <el-icon v-else-if="integration.object.sync_status === 'done'" color="green">
                    <CircleCheck/>
                  </el-icon>
                  <el-icon v-else-if="integration.object.sync_status === 'error'" color="red">
                    <CircleClose/>
                  </el-icon>
                </template>
                <template #append>
                  <el-button :icon="Refresh" @click="getIntegration"/>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button :disabled="integration.isEqual()"
                         @click="integration.reset()">Отмена
              </el-button>
              <el-button type="primary" :loading="loadSaveIntegration" :disabled="integration.isEqual()"
                         @click="saveIntegration">Сохранить
              </el-button>
              <el-button type="success" @click="openSyncForm"
                         :disabled="integration.object.sync_status === 'process'"
                         :loading="integration.object.sync_status === 'process'">
                Синхронизировать
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>

    <el-dialog v-model="visibleSyncForm"
               title="Настройка расширения VK"
               width="30%">
      <el-form label-position="top"
               ref="syncFormRef"
               :model="syncForm"
               :rules="syncFormRules">
        <el-form-item label="Дополнительное поле VK" prop="amocrm_custom_field_id"
                      :error="errors.get('amocrm_custom_field_id')">
          <el-select v-model="syncForm.amocrm_custom_field_id" placeholder="Выберете поле" style="width: 100%">
            <el-option
                v-for="custom_field in amocrm_custom_fields"
                :key="custom_field.id"
                :label="custom_field.name"
                :value="custom_field.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Способ выбора сделки" prop="selection_method"
                      :error="errors.get('selection_method')">
          <el-select v-model="syncForm.selection_method" placeholder="Выберете поле" style="width: 100%">
            <el-option
                v-for="(name, key) in selectionMethods"
                :key="key"
                :label="name"
                :value="key"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="visibleSyncForm = false">Отмена</el-button>
                <el-button @click="sync" type="success" :loading="loadSyncAmocrm">Синхронизировать</el-button>
              </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {Refresh} from '@element-plus/icons-vue'
import {Errors} from "@/utils/errors";
import {
  CHECK_INTEGRATION,
  CONNECT_AMOCRM,
  DISCONNECT_AMOCRM,
  GET_INTEGRATION,
  SAVE_INTEGRATION,
  SYNC_AMOCRM
} from "@/store/actions";
import {SET_AMOCRM_ACCOUNT, SET_AMOCRM_CUSTOM_FIELDS, SET_INTEGRATION} from "@/store/mutations";

export default {
  setup() {
    return {Refresh}
  },
  name: 'nexus-integration',
  data() {
    return {
      errors: new Errors(),
      loadConnectAmocrm: false,
      loadDisconnectAmocrm: false,
      loadCheckAmocrm: false,
      loadSyncAmocrm: false,
      loadSaveIntegration: false,

      visibleSyncForm: false,
      syncForm: {
        amocrm_custom_field_id: 0,
        selection_method: "",
      },
      syncFormRules: {
        amocrm_custom_field_id: [{required: true, message: 'Выберите доп. поле VK', trigger: 'blur'}],
        selection_method: [{required: true, message: 'Укажите способ выбора сделки', trigger: 'blur'}],
      },
      integrationFormRules: {
        selection_method: [{required: true, message: 'Укажите способ выбора сделки', trigger: 'blur'}],
      },
      selectionMethods: {
        last_create_active: "Последняя созданная и активная",
        last_update_active: "Последняя обновленная и активная",
        last_create: "Последняя созданная",
        last_update: "Последняя обновленная",
      },
      statuses: {
        process: "Выполняется настройка интеграции",
        done: "Интеграция настроена и работает",
        error: "Ошибка настройки интеграции",
      },
    }
  },
  methods: {
    connectAmocrm() {
      this.loadConnectAmocrm = true;
      this.$store.dispatch(CONNECT_AMOCRM, this.$route.params.project_id).then((resp) => {
        let url = resp.data.data;
        let title = "Предоставить доступ для интеграции";

        let w = 750;
        let h = 580;
        let dual_screen_left = window.screenLeft !== undefined ? window.screenLeft : screen.left;
        let dual_screen_top = window.screenTop !== undefined ? window.screenTop : screen.top;

        let width = window.innerWidth
            ? window.innerWidth
            : (document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width);

        let height = window.innerHeight
            ? window.innerHeight
            : (document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height);

        let left = ((width / 2) - (w / 2)) + dual_screen_left;
        let top = ((height / 2) - (h / 2)) + dual_screen_top;

        let popup = window.open(url, title, 'scrollbars, status, resizable, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        let handler = (resp) => {
          switch (resp.data.status) {
            case 200:
              console.log(resp.data.response)
              this.$store.commit(SET_INTEGRATION, resp.data.response.integration);
              this.$store.commit(SET_AMOCRM_ACCOUNT, resp.data.response.integration.amocrm_account);
              this.$store.commit(SET_AMOCRM_CUSTOM_FIELDS, resp.data.response.integration.amocrm_account.amocrm_custom_fields);

          }
          window.removeEventListener('message', handler);
          popup.close();
        };
        window.addEventListener('message', handler);
        this.loadConnectAmocrm = false;
      }).catch(() => {
        this.loadConnectAmocrm = false;
      })
    },
    disconnectAmocrm() {
      this.$store.dispatch(DISCONNECT_AMOCRM, this.$route.params.project_id).then(() => {
        this.loadDisconnectAmocrm = false;
      }).catch(() => {
        this.loadDisconnectAmocrm = false;
      })
    },
    openSyncForm() {
      this.syncForm.amocrm_custom_field_id = this.integration.object.amocrm_custom_field_id
      this.syncForm.selection_method = this.integration.object.selection_method

      if (this.syncForm.amocrm_custom_field_id === 0) {
        this.syncForm.amocrm_custom_field_id = (this.amocrm_custom_fields.find((cf) => ['vk', 'вк', 'вконтакте'].includes(cf.name.toLowerCase())) || {}).id || null;
      }
      if (this.syncForm.selection_method === '') {
        this.syncForm.selection_method = Object.keys(this.selectionMethods)[0] || "";
      }
      this.visibleSyncForm = true
    },
    sync() {
      this.errors.clear();
      this.$refs.syncFormRef.validate((valid) => {
        if (valid) {
          this.loadSyncAmocrm = true;
          this.$store.dispatch(SYNC_AMOCRM, {
            project_id: this.$route.params.project_id,
            data: this.syncForm,
          }).then(() => {
            this.loadSyncAmocrm = false;
            this.visibleSyncForm = false;
          }).catch((err) => {
            this.loadSyncAmocrm = false;
            this.errors.record(err.response.data.errors);
          })
        }
      });
    },
    checkAmocrm() {
      this.loadCheckAmocrm = true;
      this.$store.dispatch(CHECK_INTEGRATION, this.$route.params.project_id).then(() => {
        this.loadCheckAmocrm = false;
        this.$message({
          showClose: true,
          message: 'Подключение к AMOCRM выполнено успешно.',
          type: 'success'
        });
      }).catch(() => {
        this.loadCheckAmocrm = false;
        this.$message({
          showClose: true,
          message: 'Не удалось подключиться к AMOCRM. Попробуйте переподключить аккаунт. ',
          type: 'error'
        });
      })
    },
    getIntegration() {
      this.$store.dispatch(GET_INTEGRATION, this.$route.params.project_id).then(() => {
      }).catch(() => {
      })
    },
    saveIntegration() {
      this.errors.clear();
      this.$refs.integrationFormRef.validateField('selection_method',(valid) => {
        if (valid) {
          this.loadSaveIntegration = true;
          this.$store.dispatch(SAVE_INTEGRATION, {
            project_id: this.$route.params.project_id,
            data: this.integration.object,
          }).then(() => {
            this.loadSaveIntegration = false;
          }).catch((err) => {
            this.loadSaveIntegration = false;
            this.errors.record(err.response.data.errors);
          })
        }
      });
    }
  },
  computed: {
    integration() {
      return this.$store.getters.integration
    },
    amocrm_account() {
      return this.$store.getters.amocrm_account
    },
    amocrm_custom_fields() {
      return this.$store.getters.amocrm_custom_fields
    },
  }
}
</script>

<style>
.input-refresh .el-input-group__append:hover {
  background: lightgray;
}
</style>
