import {createStore} from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import integration from './modules/integration'
import project from './modules/project'

export default createStore({
    modules: {
        auth,
        user,
        integration,
        project,
    }
})
