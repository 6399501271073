export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const RESTORE = 'RESTORE';
export const GET_RESTORE_CODE = 'GET_RESTORE_CODE';

export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const SAVE_USER = 'SAVE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const GET_PROJECT = 'GET_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const PAY = 'PAY';

export const ADD_RIGHT = 'ADD_RIGHT';
export const SAVE_RIGHT = 'SAVE_RIGHT';
export const DELETE_RIGHT = 'DELETE_RIGHT';

export const CONNECT_AMOCRM = 'CONNECT_AMOCRM';
export const SYNC_AMOCRM = 'SYNC_AMOCRM';
export const DISCONNECT_AMOCRM = 'DISCONNECT_AMOCRM';

export const GET_INTEGRATION = 'GET_INTEGRATION';
export const SAVE_INTEGRATION = 'SAVE_INTEGRATION';
export const CHECK_INTEGRATION = 'CHECK_INTEGRATION';