import {FormObject} from "@/utils/form-object";
import {
    CHECK_INTEGRATION,
    CONNECT_AMOCRM,
    DISCONNECT_AMOCRM,
    GET_INTEGRATION,
    SAVE_INTEGRATION,
    SYNC_AMOCRM
} from "@/store/actions";
import axios from "axios";
import {SET_AMOCRM_ACCOUNT, SET_AMOCRM_CUSTOM_FIELDS, SET_INTEGRATION} from "@/store/mutations";

const state = {
    integration: new FormObject(),
    amocrm_account: {},
    amocrm_custom_fields: [],
};

const getters = {
    integration: state => state.integration || new FormObject(),
    amocrm_account: state => state.amocrm_account || {},
    amocrm_custom_fields: state => state.amocrm_custom_fields || [],
};

const actions = {
    [CONNECT_AMOCRM]: (_, project_id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/integration/connect`,
                method: 'POST',
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SYNC_AMOCRM]: ({commit}, {project_id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/integration/sync`,
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(SET_INTEGRATION, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [GET_INTEGRATION]: ({commit}, project_id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/integration`,
                method: 'GET',
            })
                .then(resp => {
                    commit(SET_INTEGRATION, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SAVE_INTEGRATION]: ({commit}, {project_id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/integration`,
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    commit(SET_INTEGRATION, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [CHECK_INTEGRATION]: (_, project_id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/integration/check`,
                method: 'POST',
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DISCONNECT_AMOCRM]: ({commit}, project_id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/integration/disconnect`,
                method: 'POST',
            })
                .then(resp => {
                    commit(SET_INTEGRATION, {})
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {
    [SET_INTEGRATION]: (state, integration) => {
        state.integration = new FormObject(integration);
    },
    [SET_AMOCRM_ACCOUNT]: (state, amocrm_account) => {
        state.amocrm_account = amocrm_account;
    },
    [SET_AMOCRM_CUSTOM_FIELDS]: (state, amocrm_custom_fields) => {
        state.amocrm_custom_fields = amocrm_custom_fields;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}