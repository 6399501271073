<template>
  <div>
    <el-collapse v-model="collapse">
      <el-collapse-item name="1">
        <template #title>
          <span style="font-size: 16px; font-weight: bold">Активные подписки</span>
        </template>
        <el-table :data="active_licenses" style="width: 100%">
          <el-table-column prop="number_users" label="Количество пользователей">
            <template #default="scope">
              <span style="font-size: 16px; font-weight: bold">{{ scope.row.number_users }}
                пользовател{{ scope.row.number_users === 1 ? 'ь' : scope.row.number_users <= 4 ? 'я' : 'ей' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="start_date" label="Начало периода" width="180">
            <template #default="scope">
              <span>{{ toDate(scope.row.start_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="end_date" label="Конец периода" width="180">
            <template #default="scope">
              <span>{{ toDate(scope.row.end_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="end_date" label="Дата оплаты" width="180">
            <template #default="scope">
              <span>{{ new Date(scope.row.date).toLocaleDateString() }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <el-button @click="openPayForm()" type="primary">+ Добавить</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template #title>
          <span style="font-size: 16px; font-weight: bold">Ожидают активации</span>
        </template>
        <el-table :data="future_licenses" style="width: 100%">
          <el-table-column prop="number_users" label="Количество пользователей">
            <template #default="scope">
              <span style="font-size: 16px; font-weight: bold">{{ scope.row.number_users }}
                пользовател{{ scope.row.number_users === 1 ? 'ь' : scope.row.number_users <= 4 ? 'я' : 'ей' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="start_date" label="Начало периода" width="180">
            <template #default="scope">
              <span>{{ toDate(scope.row.start_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="end_date" label="Конец периода" width="180">
            <template #default="scope">
              <span>{{ toDate(scope.row.end_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="end_date" label="Дата оплаты" width="180">
            <template #default="scope">
              <span>{{ new Date(scope.row.date).toLocaleDateString() }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
      <el-collapse-item name="3">
        <template #title>
          <span style="font-size: 16px; font-weight: bold">Завершенные</span>
        </template>
        <el-table :data="completed_licenses" style="width: 100%">
          <el-table-column prop="number_users" label="Количество пользователей">
            <template #default="scope">
              <span style="font-size: 16px; font-weight: bold">{{ scope.row.number_users }}
                пользовател{{ scope.row.number_users === 1 ? 'ь' : scope.row.number_users <= 4 ? 'я' : 'ей' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="start_date" label="Начало периода" width="180">
            <template #default="scope">
              <span>{{ toDate(scope.row.start_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="end_date" label="Конец периода" width="180">
            <template #default="scope">
              <span>{{ toDate(scope.row.end_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="end_date" label="Дата оплаты" width="180">
            <template #default="scope">
              <span>{{ new Date(scope.row.date).toLocaleDateString() }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>

    <el-dialog v-model="visiblePayForm"
               title="Покупка доступа"
               width="500px">
      <el-form label-position="top"
               :model="payForm">
        <el-form-item label="Количество пользователей" prop="number_users"
                      :error="errors.get('number_users')">
          <el-input-number v-model="payForm.number_users" :min="1"/>
        </el-form-item>
        <el-form-item label="Дата начала периода" prop="start_date"
                      :error="errors.get('start_date')">
          <el-select v-model="payForm.start_date"
                     @change="this.payForm.end_date=''">
            <el-option
                v-for="item in start_dates"
                :key="item.getTime()"
                :label="toDate(item)"
                :value="item.getTime()"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Дата конец периода" prop="end_date"
                      :error="errors.get('end_date')">
          <el-select v-model="payForm.end_date" :disabled="payForm.start_date===''">
            <el-option
                v-for="item in end_dates"
                :key="item.getTime()"
                :label="toDate(item) + ' (' + Math.round((item-payForm.start_date)/86400000) + ' дней)'"
                :value="item.getTime()"
            />
          </el-select>
        </el-form-item>

      </el-form>
      <template #footer>
        <el-button @click="visiblePayForm = false">Отмена</el-button>
        <el-button type="primary" @click="pay()" :disabled="count_day===0">
          Оплатить {{ 10 * count_day * payForm.number_users }} руб. ({{ count_day }} дней)
        </el-button>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import {Errors} from "@/utils/errors";
import {PAY} from "@/store/actions";

export default {
  name: 'nexus-pay',
  data() {
    return {
      errors: new Errors(),
      collapse: ["1"],
      visiblePayForm: false,
      loadPayForm: false,
      payForm: {
        date: "",
        start_date: "",
        end_date: "",
        number_users: 1,
        is_addition: false,
      },
      payFormRules: {
        number_users: [{required: true, message: 'Введите email', trigger: 'blur'}],
      },
    }
  },
  methods: {
    openPayForm() {
      this.visiblePayForm = true
    },
    pay() {
      let project_id = this.$route.params.project_id
      let data = this.payForm
      this.$store.dispatch(PAY, {project_id, data}).then((resp) => {
        window.open(resp.data.data, "_self")
      })
    },
    toDate(date) {
      let d = new Date(date)
      return ('0' + d.getUTCDate()).slice(-2) + '.' + ('0' + (d.getUTCMonth() + 1)).slice(-2) + '.' + d.getUTCFullYear();
    }
  },
  computed: {
    active_licenses() {
      let now = new Date(new Date().toString().split('GMT')[0] + 'UTC')
      return this.$store.getters.licenses.filter((l) => {
        return new Date(l.start_date).getTime() < now.getTime() && now.getTime() < new Date(l.end_date).getTime()
      })
    },
    future_licenses() {
      let now = new Date(new Date().toString().split('GMT')[0] + 'UTC')
      return this.$store.getters.licenses.filter((l) => {
        return now.getTime() < new Date(l.start_date).getTime()
      })
    },
    completed_licenses() {
      let now = new Date(new Date().toString().split('GMT')[0] + 'UTC')
      return this.$store.getters.licenses.filter((l) => {
        return new Date(l.end_date).getTime() < now.getTime()
      })
    },
    start_dates() {
      //первые дни после окончаний лицензий
      let dates = this.active_licenses.concat(this.future_licenses).map((license) => {
        let date = new Date(new Date(license.end_date).setUTCHours(0, 0, 0, 0))
        date.setDate(date.getDate() + 1)
        return date.getTime()
      })

      //добавляем сегодняшний день
      dates.push(new Date(new Date(new Date().setHours(0, 0, 0, 0)).toString().split('GMT')[0] + 'UTC').getTime())

      //удаляем дубли
      dates = dates.filter((value, index, array) => {
        return array.indexOf(value) === index
      })

      //сортируем
      dates.sort()

      return dates.map((start_date) => {
        return new Date(start_date)
      })
    },
    end_dates() {
      //окончания последних дней активных и будущих лицензий
      let dates = this.active_licenses.concat(this.future_licenses).map((license) => {
        return new Date(new Date(license.end_date).setUTCHours(23, 59, 59, 0)).getTime()
      })

      //фильтруем все что больше старта
      dates = dates.filter((value) => {
        return new Date(value).getTime() > new Date(this.payForm.start_date).getTime()
      })

      let t = new Date(this.payForm.start_date)
      t = new Date(new Date(t.setDate(t.getDate() - 1)).setUTCHours(23, 59, 59, 0))
      if (dates.length !== 0) {
        t = new Date(new Date(dates[dates.length - 1]).setUTCHours(23, 59, 59, 0))
      }

      t.setDate(t.getDate() + 30)
      dates.push(t.getTime())
      t.setDate(t.getDate() + 30)
      dates.push(t.getTime())
      t.setDate(t.getDate() + 30)
      dates.push(t.getTime())

      //удаляем дубли
      dates = dates.filter((value, index, array) => {
        return array.indexOf(value) === index
      })

      //сортируем
      dates.sort()

      return dates.map((end_date) => {
        return new Date(end_date)
      })
    },

    balance() {
      return this.$store.getters.balance
    },
    count_day() {
      if (this.payForm.start_date === '' || this.payForm.end_date === '' ||
          this.payForm.start_date >= this.payForm.end_date) {
        return 0
      }
      return Math.round((this.payForm.end_date - this.payForm.start_date) / 86400000)
    },
  }
}
</script>

<style scoped>
</style>
