<template>
  <el-row>
    <el-col :span="18" :offset="3"
            :style="{'min-height': '400px', padding: `0 20px`, boxShadow: `var(--el-box-shadow)`}">
      <h3>Контакты и реквизиты</h3>
      <div style="font-size: 16px">
        ФИО: Мальцев Иван Александрович<br>
        ИНН: 432102461854<br>
        E-mail: support@macrm.ru<br>
        Номер телефона: +79229910525
      </div>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: 'nexus-contact',
}
</script>

<style>
d {
  min-height: ;
}
</style>
