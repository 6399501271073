<template>
  <el-row>
    <el-col :span="6" :offset="9">
      <el-card class="box-card" header="Восстановление пароля">
        <el-form ref="restoreFormRef"
                 :model="restoreForm"
                 :rules="restoreFormRules"
                 label-position="top">
          <el-form-item label="Email" prop="value" :error="errors.get('value')">
            <el-input v-model="restoreForm.value" :readonly="visiblePasswordFields"/>
          </el-form-item>
          <el-form-item v-if="visiblePasswordFields" label="Код восстановления" prop="code"
                        :error="errors.get('code')">
            <el-input v-model="restoreForm.code"/>
          </el-form-item>
          <el-form-item v-if="visiblePasswordFields" label="Введите новый пароль" prop="password"
                        show-password :error="errors.get('password')">
            <el-input v-model="restoreForm.password"/>
          </el-form-item>
          <el-form-item v-if="visiblePasswordFields" label="Подтвердите новый пароль" prop="password_confirm"
                        show-password :error="errors.get('password_confirm')">
            <el-input v-model="restoreForm.password_confirm"/>
          </el-form-item>
          <el-form-item>
            <el-button v-if="!visiblePasswordFields" type="primary" @click="getRestoreCode" style="width: 100%">
              Получить код
            </el-button>
            <el-button v-else type="primary" @click="restore" style="width: 100%">
              Восстановить пароль
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="$router.push('/registration')" style="width: 100%">
              Зарегистрироваться
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="$router.push('/login')" style="width: 100%" link>
              Вход
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {Errors} from "@/utils/errors";
import {GET_RESTORE_CODE, RESTORE} from "@/store/actions";

export default {
  name: 'nexus-restore',
  data() {
    return {
      errors: new Errors(),
      loadRestoreForm: false,
      visiblePasswordFields: false,
      restoreForm: {
        type: "email",
        value: "",
        code: "",
        password: "",
        password_confirm: "",
      },
      restoreFormRules: {
        value: [{required: true, message: 'Введите email', trigger: 'blur'}],
        code: [{required: true, message: 'Введите код восстановления', trigger: 'blur'}],
        password: [{required: true, message: 'Введите новый пароль', trigger: 'blur'}],
        password_confirm: [{required: true, message: 'Подтвердите новый пароль', trigger: 'blur'}],
      },
    }
  },
  methods: {
    getRestoreCode() {
      this.errors.clear();
      this.$refs.restoreFormRef.validateField("value", (valid) => {
        if (valid) {
          this.loadRestoreForm = true;
          this.$store.dispatch(GET_RESTORE_CODE, this.restoreForm).then(() => {
            this.visiblePasswordFields = true;
            this.loadRestoreForm = false;
          }).catch((err) => {
            this.loadRestoreForm = false;
            this.errors.record(err.response.data.errors);
          })
        }
      });
    },
    restore() {
      this.errors.clear();
      this.$refs.restoreFormRef.validate((valid) => {
        if (valid) {
          this.loadRestoreForm = true;
          this.$store.dispatch(RESTORE, this.restoreForm).then(() => {
            this.loadRestoreForm = false;
            this.$router.push("/profile");
          }).catch((err) => {
            this.loadRestoreForm = false;
            this.errors.record(err.response.data.errors);
          })
        }
      });
    }
  },
}
</script>

<style>
</style>
