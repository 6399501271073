<template>
  <el-row>
    <el-col :span="11">
      <el-form :model="project.object" ref="projectFormRef" :rules="projectRules" label-position="top">
        <el-form-item prop="name" :error="errors.get('name')" label="Название проекта">
          <el-input v-model="project.object.name" placeholder='Например: ООО "МК"' :disabled="!right.is_owner && !right.is_admin"/>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="project.isEqual()" @click="project.reset()">Отмена</el-button>
          <el-button :disabled="project.isEqual()" :loading="loadSaveProject" type="primary"
                     @click="save">
            Сохранить
          </el-button>
          <el-popconfirm
              v-if="right.is_owner"
              confirm-button-text="Да"
              cancel-button-text="Нет"
              confirm-button-type="danger"
              title="Вы уверены?"
              @confirm="deleteProject">
            <template #reference>
              <el-button :loading="loadDeleteProject" type="danger">
                Удалить проект
              </el-button>
            </template>
          </el-popconfirm>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import {Errors} from "@/utils/errors";
import {DELETE_PROJECT, SAVE_PROJECT} from "@/store/actions";

export default {
  name: 'nexus-setting',
  data() {
    return {
      errors: new Errors(),
      loadSaveProject: false,
      loadDeleteProject: false,
      projectRules: {
        name: [{required: true, message: 'Введите название проекта', trigger: 'blur'}]
      },
    }
  },
  methods: {
    save() {
      this.errors.clear();
      this.$refs.projectFormRef.validate((valid) => {
        if (valid) {
          this.loadSaveProject = true;
          this.$store.dispatch(SAVE_PROJECT, {
            id: this.project.object.id,
            data: this.project.object,
          }).then(() => {
            this.loadSaveProject = false;
          }).catch((err) => {
            this.errors.record(err.response.data.errors);
            this.loadSaveProject = false;
          })
        }
      });
    },
    deleteProject() {
      this.loadDeleteProject = true;
      this.$store.dispatch(DELETE_PROJECT, this.project.object.id).then(() => {
        this.$router.push("/")
        this.loadDeleteProject = false;
      }).catch(() => {
        this.loadDeleteProject = false;
      })
    }
  },
  computed: {
    project() {
      return this.$store.getters.project
    },
    right() {
      return this.$store.getters.right
    },
  }
}
</script>

<style scoped>
</style>
