<template>
  <el-row>
    <el-col :span="18" :offset="3" :style="{boxShadow: `var(--el-box-shadow)`}">
      <div style="padding: 20px;">
        <el-tabs type="border-card" :model-value="hash" @tab-click="tabClick">
          <el-tab-pane name="#setting" label="Настройки">
            <nexus-setting/>
          </el-tab-pane>
          <el-tab-pane name="#integration" v-if="right.is_owner || right.is_admin" label="Интеграции">
            <nexus-integration/>
          </el-tab-pane>
          <el-tab-pane name="#right" v-if="right.is_owner || right.is_admin" label="Права доступа">
            <nexus-right/>
          </el-tab-pane>
          <el-tab-pane name="#pay" v-if="right.is_owner || right.is_admin" label="Оплата">
            <nexus-pay/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import NexusSetting from "@/components/main/project/NexusSetting";
import NexusIntegration from "@/components/main/project/NexusIntegration";
import NexusRight from "@/components/main/project/NexusRight";
import NexusPay from "@/components/main/project/NexusPay";

export default {
  name: 'nexus-project',
  components: {NexusPay, NexusRight, NexusIntegration, NexusSetting},
  methods: {
    tabClick(tab) {
      this.$router.push({hash: tab.paneName})
    }
  },
  computed: {
    right() {
      return this.$store.getters.right
    },
    hash() {
      return this.$route.hash || '#setting'
    },
  }
}
</script>

<style scoped>
</style>
