export const SET_USER = 'SET_USER';
export const SET_CONTACTS = 'SET_CONTACTS';

export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECT = 'SET_PROJECT';
export const PUSH_PROJECT = 'PUSH_PROJECT';
export const POP_PROJECT = 'POP_PROJECT';

export const SET_LICENSES = 'SET_LICENSES';
export const PUSH_LICENSE = 'PUSH_LICENSE';

export const SET_RIGHTS = 'SET_RIGHTS';
export const SET_RIGHT = 'SET_RIGHT';
export const PUSH_RIGHT = 'PUSH_RIGHT';
export const POP_RIGHT = 'POP_RIGHT';

export const SET_INTEGRATION = 'SET_INTEGRATION';
export const SET_AMOCRM_ACCOUNT = 'SET_AMOCRM_ACCOUNT';
export const SET_AMOCRM_CUSTOM_FIELDS = 'SET_AMOCRM_CUSTOM_FIELDS';


