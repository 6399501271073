import {createRouter, createWebHistory} from 'vue-router'
import NexusMain from "@/components/NexusMain";
import NexusProject from "@/components/main/NexusProject";
import NexusProfile from "@/components/main/NexusProfile";
import NexusContact from "@/components/main/NexusContact";
import NexusLogin from "@/components/main/NexusLogin";
import NexusRegistration from "@/components/main/NexusRegistration";
import NexusRestore from "@/components/main/NexusRestore";
import VueCookies from 'vue-cookies'


const routes = [
    {
        path: '/login',
        name: 'NexusLogin',
        component: NexusLogin,
        beforeEnter() {
            if (VueCookies.get("SSID")) {
                router.push('/profile')
            }
        }
    },
    {
        path: '/registration',
        name: 'NexusRegistration',
        component: NexusRegistration,
        beforeEnter() {
            if (VueCookies.get("SSID")) {
                router.push('/profile')
            }
        }
    },
    {
        path: '/restore',
        name: 'NexusRestore',
        component: NexusRestore,
        beforeEnter() {
            if (VueCookies.get("SSID")) {
                router.push('/profile')
            }
        }
    },
    {
        path: '/',
        name: 'NexusMain',
        component: NexusMain,
        beforeEnter() {
            if (!VueCookies.get("SSID")) {
                router.push('/login')
            }
        },
        children: [
            {
                path: '/project/:project_id',
                name: 'NexusProject',
                component: NexusProject
            },
            {
                path: '/profile',
                name: 'NexusProfile',
                component: NexusProfile
            },
            {
                path: '/contact',
                name: 'NexusContact',
                component: NexusContact
            }
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router
