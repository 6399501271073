<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <el-col>
        <el-button @click="openAddRightForm">Пригласить пользователя</el-button>
        <el-button @click="openAddUserForm" type="primary">Добавить нового пользователя</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="rights" style="width: 100%">
        <el-table-column prop="id" label="ID" width="100"/>
        <el-table-column label="Имя" width="250">
          <template #default="scope">
            {{ scope.row.user.name }}
          </template>
        </el-table-column>
        <el-table-column label="Уровень доступа" width="250">
          <template #default="scope">
            {{ scope.row.is_owner ? 'Владелец' : scope.row.is_admin ? 'Администратор' : 'Пользователь' }}
          </template>
        </el-table-column>
        <el-table-column label="Статус">
          <template #default="scope">
            {{ scope.row.is_active ? 'Активен' : 'Неактивен' }}
            <el-button link type="danger" v-if="scope.row.is_active" @click="onOffRight(scope.row, false)">
              (Выключить)
            </el-button>
            <el-button link type="success" v-else @click="onOffRight(scope.row, true)">(Включить)</el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="120">
          <template #default="scope">
            <el-button v-if="!scope.row.is_owner && right.user_id !== scope.row.user_id" :icon="Edit" link
                       @click="openEditRightForm(scope.row)"/>
            <el-popconfirm
                v-if="!scope.row.is_owner && right.user_id !== scope.row.user_id"
                confirm-button-text="Да"
                cancel-button-text="Нет"
                confirm-button-type="danger"
                title="Вы уверены?"
                @confirm="deleteRight(scope.row.id)">
              <template #reference>
                <el-button type="danger" :icon="Delete" link/>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-dialog v-model="visibleAddRight"
               title="Пригласить пользователя"
               class="box-card"
               width="500px">
      <el-form label-position="top"
               ref="addRightFormRef"
               :model="addRightForm"
               :rules="addRightFormRules">
        <el-form-item label="Email пользователя" prop="value"
                      :error="errors.get('value')">
          <el-input v-model="addRightForm.value" placeholder="Введите email пользователя"/>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="addRightForm.is_admin">Предоставить права администратора</el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="visibleAddRight = false">Отмена</el-button>
        <el-button type="primary" @click="addRight()" :loading="loadAddRight">Добавить</el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="visibleEditRight"
               title="Редактирование прав пользователя"
               width="500px">
      <el-form label-position="top"
               :model="editRightForm.origin">
        <el-form-item label="Имя пользователя">
          <el-input v-model="editRightForm.origin.user.name" readonly/>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="editRightForm.object.is_admin">Предоставить права администратора</el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="visibleEditRight = false">Отмена</el-button>
        <el-button type="primary" @click="editRight(editRightForm.object)" :loading="loadEditRight"
                   :disabled="editRightForm.isEqual()">
          Сохранить
        </el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="visibleAddUser"
               title="Добавление нового пользователя"
               width="500px">
      <el-form label-position="top"
               ref="addUserFormRef"
               :model="addUserForm"
               :rules="addUserFormRules">
        <el-form-item label="Имя пользователя" prop="name" :error="errors.get('name')">
          <el-input v-model="addUserForm.name" placeholder="Введите имя пользователя"/>
        </el-form-item>
        <el-form-item label="Email пользователя" prop="value"
                      :error="errors.get('value')">
          <el-input v-model="addUserForm.value" placeholder="Введите email пользователя"/>
        </el-form-item>
        <el-form-item label="Пароль" prop="password" :error="errors.get('password')">
          <el-input v-model="addUserForm.password" placeholder="Введите пароль" autocomplete="new-password"
                    show-password/>
        </el-form-item>
        <el-form-item label="Подтвердите пароль" prop="password_confirm"
                      :error="errors.get('password_confirm')">
          <el-input v-model="addUserForm.password_confirm" placeholder="Подтвердите пароль"
                    autocomplete="new-password-confirm"
                    show-password/>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="addUserForm.is_admin">Предоставить права администратора</el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="visibleAddUser = false">Отмена</el-button>
        <el-button type="primary" @click="addUser()" :loading="loadAddUser">Добавить</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {Delete, Edit} from '@element-plus/icons-vue'</script>

<script>
import {ADD_RIGHT, ADD_USER, DELETE_RIGHT, SAVE_RIGHT} from "@/store/actions";
import {Errors} from "@/utils/errors";
import {FormObject} from "@/utils/form-object";

export default {
  name: 'nexus-right',
  data() {
    return {
      errors: new Errors(),

      visibleAddRight: false,
      loadAddRight: false,
      addRightForm: {
        type: "email"
      },
      addRightFormRules: {
        value: [{required: true, message: 'Введите email', trigger: 'blur'}],
      },

      visibleEditRight: false,
      loadEditRight: false,
      editRightForm: new FormObject({user: {}}),

      visibleAddUser: false,
      loadAddUser: false,
      addUserForm: {},
      addUserFormRules: {
        name: [{required: true, message: 'Введите имя пользователя', trigger: 'blur'}],
        value: [{required: true, message: 'Введите email', trigger: 'blur'}],
        password: [{required: true, message: 'Введите пароль', trigger: 'blur'}],
        password_confirm: [{required: true, message: 'Подтвердите пароль', trigger: 'blur'}],
      },


    }
  },
  methods: {
    openAddRightForm() {
      this.errors.clear();
      this.addRightForm = {type: "email", is_admin: false}
      this.visibleAddRight = true
    },
    addRight() {
      this.errors.clear();
      this.$refs.addRightFormRef.validate((valid) => {
        if (valid) {
          this.loadAddRight = true;
          this.$store.dispatch(ADD_RIGHT, {
            project_id: this.project.origin.id,
            data: this.addRightForm,
          }).then(() => {
            this.loadAddRight = false;
            this.visibleAddRight = false;
          }).catch((err) => {
            this.errors.record(err.response.data.errors);
            this.loadAddRight = false;
          })
        }
      })
    },
    openAddUserForm() {
      this.errors.clear();
      this.addUserForm = {type: "email", is_admin: false}
      this.visibleAddUser = true
    },
    addUser() {
      this.errors.clear();
      this.$refs.addUserFormRef.validate((valid) => {
        if (valid) {
          this.loadAddUser = true;
          this.$store.dispatch(ADD_USER, {
            project_id: this.project.origin.id,
            data: this.addUserForm,
          }).then(() => {
            this.loadAddUser = false;
            this.visibleAddUser = false;
          }).catch((err) => {
            this.errors.record(err.response.data.errors);
            this.loadAddUser = false;
          })
        }
      });

    },
    onOffRight(right, is_active) {
      let r = new FormObject(right)
      r.object.is_active = is_active
      this.editRight(r.object)
    },
    openEditRightForm(right) {
      this.editRightForm = new FormObject(right);
      this.visibleEditRight = true;
    },
    editRight(right) {
      let project_id = this.project.origin.id;
      let right_id = right.id;
      this.$store.dispatch(SAVE_RIGHT, {project_id, right_id, right}).then(() => {
        this.visibleEditRight = false;
      }).catch((err) => {
        console.log(err.response.data.errors)
        if (Array.isArray(err.response.data.errors) && err.response.data.errors.length > 0)
        this.$message({
          showClose: true,
          message: 'Ошибка: ' + err.response.data.errors[0].title,
          type: 'error'
        });
      })
    },
    deleteRight(right_id) {
      let project_id = this.project.origin.id
      this.$store.dispatch(DELETE_RIGHT, {project_id, right_id}).then(() => {
      }).catch(() => {
      })
    },
  },
  computed: {
    rights() {
      return this.$store.getters.rights
    },
    right() {
      return this.$store.getters.right
    },
    project() {
      return this.$store.getters.project
    },
  }
}
</script>

<style scoped>
</style>
