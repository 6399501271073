<template>
  <el-row>
    <el-col :span="18" :offset="3" :style="{padding: `0 20px`, boxShadow: `var(--el-box-shadow)`}">
      <h3>Настройки пользователя</h3>
      <el-row>
        <el-col :span="11">
          <el-form :model="user.object" ref="userFormRef" :rules="userFormRules" label-position="top">
            <el-form-item label="ФИО пользователя" prop="name" :error="errors.get('name')">
              <el-input v-model="user.object.name" placeholder="ФИО пользователя"/>
            </el-form-item>
            <el-form-item>
              <el-button :disabled="user.isEqual()" @click="user.reset()">Отмена</el-button>
              <el-button :loading="loadSave" :disabled="user.isEqual()" type="primary" @click="save">
                Сохранить
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <h4>Контактные данные</h4>
      <!--el-row>
        <el-col>
          <el-form-item>
            <el-button type="primary" @click="openAddEmailForm">Добавить email</el-button>
          </el-form-item>
        </el-col>
      </el-row-->
      <el-row>
        <el-col :span="11">
          <el-form-item v-for="contact in contacts" :key="contact.type+contact.value">
            <el-input v-model="contact.value" placeholder="Email"
                      :prefix-icon="icons.email"
                      :suffix-icon="contact.is_confirm ? icons.success : icons.warning"
                      readonly/>
          </el-form-item>
        </el-col>
      </el-row>
      <h4>Изменение пароля</h4>
      <el-form :model="passwordForm" ref="passwordFormRef" :rules="passwordFormRules" label-position="top">
        <el-row>
          <el-col :span="11">
            <el-form-item label="Старый пароль" prop="password" :error="errors.get('old_password')">
              <el-input v-model="passwordForm.old_password" placeholder="Старый пароль" show-password/>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="11">
            <el-form-item label="Новый пароль" prop="new_password" :error="errors.get('new_password')">
              <el-input v-model="passwordForm.new_password" placeholder="Новый пароль" show-password/>
            </el-form-item>
            <el-form-item label="Подтвердите новый пароль" prop="new_password_confirm"
                          :error="errors.get('new_password_confirm')">
              <el-input v-model="passwordForm.new_password_confirm" placeholder="Подтвердите новый пароль"
                        show-password/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
            <el-button :loading="loadChangePassword" type="primary" @click="changePassword">Изменить пароль
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>

      <el-dialog v-model="visibleAddEmailForm"
                 title="Добавление email"
                 width="30%">
        <el-form label-position="top"
                 ref="addEmailFormRef"
                 :model="addEmailForm"
                 :rules="addEmailFormRules">
          <el-form-item label="Email" prop="value"
                        :error="errors.get('value')">
            <el-input v-model="addEmailForm.value" :disabled="confirmCodeStep"/>
          </el-form-item>
          <el-form-item v-if="confirmCodeStep">
            <el-alert style="padding: 0"
                      title="Вам на адрес электронной почты отправлено письмо с кодом подтверждения. " type="success"/>
          </el-form-item>
          <el-form-item v-if="confirmCodeStep" label="Код подтверждения" prop="confirm_code"
                        :error="errors.get('confirm_code')">
            <el-input v-model="addEmailForm.confirm_code"/>
          </el-form-item>
        </el-form>
        <template #footer>
              <span>
                <el-button @click="visibleAddEmailForm = false">Отмена</el-button>
                <el-button v-if="confirmCodeStep" @click="confirmEmail" type="primary">Подтвердить</el-button>
                <el-button v-else @click="addEmail" type="primary">Добавить</el-button>
              </span>
        </template>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
import {Errors} from "@/utils/errors";
import {Iphone, Message, SuccessFilled, WarningFilled} from '@element-plus/icons-vue'
import {CHANGE_PASSWORD, SAVE_USER} from "@/store/actions";

export default {
  name: 'nexus-profile',
  data() {
    return {
      errors: new Errors(),
      visibleAddEmailForm: false,
      loadSave: false,
      loadChangePassword: false,
      confirmCodeStep: false,
      icons: {
        email: Message,
        phone: Iphone,
        success: SuccessFilled,
        warning: WarningFilled,
      },
      addEmailForm: {
        type: "email",
        value: "",
        confirm_code: "",
      },
      addEmailFormRules: {
        value: [
          {required: true, message: 'Введите email', trigger: 'blur'},
          {type: 'email', message: 'Проверьте правильность ввода email', trigger: 'blur'}
        ],
        confirm_code: [{required: true, message: 'Введите код подтверждения', trigger: 'blur'}],
      },
      userFormRules: {
        name: [{required: true, message: 'Введите ФИО', trigger: 'blur'}],
      },
      passwordForm: {},
      passwordFormRules: {
        old_password: [{required: true, message: 'Введите текущий пароль', trigger: 'blur'}],
        new_password: [{required: true, message: 'Введите новый пароль', trigger: 'blur'}],
        new_password_confirm: [
          {required: true, message: 'Подтвердите новый пароль', trigger: 'blur'},
          {
            validator: (_, value, callback) => {
              if (value !== this.passwordForm.new_password) {
                callback(new Error('Пароли не совпадают'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
      },
    }
  },
  methods: {
    save() {
      this.errors.clear();
      this.$refs.userFormRef.validate((valid) => {
        if (valid) {
          this.loadSave = true;
          this.$store.dispatch(SAVE_USER, this.user.object).then(() => {
            this.loadSave = false;
          }).catch((err) => {
            this.loadSave = false;
            this.errors.record(err.response.data.errors);
          })
        }
      });
    },
    changePassword() {
      this.errors.clear();
      this.$refs.passwordFormRef.validate((valid) => {
        if (valid) {
          this.loadChangePassword = true;
          this.$store.dispatch(CHANGE_PASSWORD, this.passwordForm).then(() => {
            this.loadChangePassword = false;
            this.passwordForm = {};
          }).catch((err) => {
            this.loadChangePassword = false;
            this.errors.record(err.response.data.errors);
          })
        }
      });
    },
    openAddEmailForm() {
      this.confirmCodeStep = false;
      this.addEmailForm.value = "";
      this.visibleAddEmailForm = true;
    },
    addEmail() {
      /*this.errors.clear();
      this.$refs.addEmailFormRef.validateField(['value'], (valid) => {
        if (valid) {
          this.$store.dispatch(ADD_CONTACT, this.addEmailForm).then(() => {
            this.confirmCodeStep = true;
          }).catch((err) => {
            this.errors.record(err.response.data.errors);
          })
        }
      });*/
    },
    confirmEmail() {
      /*this.errors.clear();
      this.$refs.addEmailFormRef.validate((valid) => {
        if (valid) {
          this.$store.dispatch(CONFIRM_CONTACT, this.addEmailForm).then(() => {
            this.visibleAddEmailForm = false;
          }).catch((err) => {
            this.errors.record(err.response.data.errors);
          })
        }
      });*/
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    contacts() {
      return this.$store.getters.contacts
    },
  }
}
</script>

<style scoped>
</style>
